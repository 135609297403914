module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Yeseva One","Raleway:400,600,700","Ubuntu Condensed"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tindebox Web Solutions","short_name":"TWS","start_url":"/","background_color":"#f2f7f4","theme_color":"#aec0d8","display":"minimal-ui","icon":"./static/cube-icon-blue.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"086419bff4bf1844221907d22cf33063"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
